* {
  box-sizing: border-box;
}

.fullScreenWrapper {
  height: 100vh;
  width: 100vw;
}

.yellow {
  background-color: yellow;
}

.authform input {
  width: 240px;
  height: 24px;
  margin-bottom: 12px;
}

.authform input[type="submit"] {
  width: 100px;
}

.expandablepane {
  overflow: hidden;
}